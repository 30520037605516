import type { E, ErrorBody } from './generated';
import { type Locale } from './src/i18n';

export type SpartaAuthLocaleChangedEventDetail = { locale: Locale };
export type SpartaAuthLocaleChangedEvent = CustomEvent<SpartaAuthLocaleChangedEventDetail>;

/**
 * Scope of our i18n definition / keys nested under `pages` object.
 * Should also match all the pages in `src/pages` directory + common.
 *
 * @see `src/i18n/index.ts` for the details.
 */
export type PageScopeType = 'common' | 'verify' | 'authorize' | 'register' | 'password' | 'consent';

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export interface ErrorOut {
  cause: E;
  error: string;
  hints: string[] | null;
  parent: ErrorBody | null;
}

/**
 * Checks if the given response is an instance of ErrorOut.
 * @param response - The response to check.
 * @returns True if the response is an instance of ErrorOut, false otherwise.
 */
export function isErrorOut(response: unknown | Object | ErrorOut): response is ErrorOut {
  return (response as ErrorOut)?.error !== undefined && (response as ErrorOut)?.cause !== undefined;
}
