import { isErrorOut } from '../../../types';
import { type LocaleScopePath, t } from '../../i18n';

export class Notifications {
  static create(title: LocaleScopePath, timeout: number | 'infinite' = 4000): void {
    const toastRegion = document.querySelector('.toast-region');

    if (!toastRegion) {
      console.warn('Toast region not found. Could not create toast.');
      return;
    }

    const uid = crypto.randomUUID();
    const toast = document.createElement('div');
    toast.innerHTML = `
      <div class="toast" role="alert" aria-labelledby="${uid}">
        <span id="${uid}" data-i18n="${title}">${t(title)}</span>
        <button type="button" aria-label="Zavřít" data-i18n="pages.common.close" i18n-target="ariaLabel" class="close">x</button>
      </div>
    `;

    const closeButton = toast.querySelector('.close');
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        toastRegion.removeChild(toast);
      });
    }

    toastRegion.appendChild(toast);

    if (timeout !== 'infinite') {
      setTimeout(() => {
        if (toastRegion.contains(toast)) {
          toastRegion.removeChild(toast);
        }
      }, timeout);
    }
  }

  static createErrorToast(error: any, context: string = 'Submit error:') {
    console.error(context, error);

    if (isErrorOut(error)) {
      // Show more detailed error message for BE errors?
    }

    Notifications.create('pages.common.notifications.errors.generic');
  }
}
