import type { PageScopeType } from '../../../types';
import { t } from '../../i18n';

/**
 * Sets the metadata for the current locale.
 * * Meta title needs to be set for all pages (scopes).
 * * Meta description is set to the default value from `pages.common` as it's static.
 */
export function setMetadataForLocale() {
  const scope = document.documentElement.getAttribute('data-scope') as PageScopeType | null;

  let metaTitle = t('pages.common.metaTitle');
  let metaDescription = t('pages.common.metaDescription');

  if (scope) {
    const scopeMetaTitle = t(`pages.${scope}.metaTitle`);

    if (scopeMetaTitle && scopeMetaTitle !== `pages.${scope}.metaTitle`) {
      metaTitle = scopeMetaTitle;
    }
  }

  // Set meta title
  document.title = metaTitle;

  // Set og meta title
  const metaOgTitleTag = document.querySelector('meta[property="og:title"]');
  if (metaOgTitleTag) {
    metaOgTitleTag.setAttribute('content', metaTitle);
  }

  // Set meta description
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute('content', metaDescription);
  }

  // Set og meta description
  const metaOgDescriptionTag = document.querySelector('meta[property="og:description"]');
  if (metaOgDescriptionTag) {
    metaOgDescriptionTag.setAttribute('content', metaDescription);
  }
}
