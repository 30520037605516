/**
 * Localstorage key for email prefill - used in register / authorize form.
 */
export const EMAIL_PREFILL_LOCAL_STORAGE_KEY = 'spt_id_email_prefill' as const;

/**
 *  Cookie key for locale - used in i18n. Should be shared with backend.
 */
export const LOCALE_COOKIE_KEY = 'locale' as const;

/**
 * Html attribute for i18n path. Used in HTML and TS
 * @see src/i18n/index.ts
 */
export const i18n_PATH_ATTRIBUTE = 'data-i18n' as const;

/**
 * Html attribute for i18n target. Used in HTML and TS
 * @see src/i18n/index.ts
 */
export const i18n_TARGET_ATTRIBUTE = 'i18n-target' as const;

/**
 * Html attribute for i18n dynamic value. Used in HTML and TS
 * @see src/i18n/index.ts
 */
export const i18n_VALUES_ATTRIBUTE = 'i18n-values' as const;

/**
 * Html attribute for default value. Used in HTML and TS
 */
export const DATA_DEFAULT_ATTRIBUTE = 'data-default' as const;

export const PASSWORD_TIMEOUT_LOCALSTORAGE_KEY = 'spt_id_password_timeout';
export const RETRY_TIMEOUT_SECONDS = 60;

export const QUERY_PARAM_LOCALE_KEY = 'ui_locale';
export const QUERY_PARAM_ERROR_KEY = 'error';
